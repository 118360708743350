/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import api from './index';

const prefix = 'dashboard';
const getDashboard = (query) =>
  api.get(
    `${prefix}?${Object.keys(query)
      .map((key) => `${key}=${query[key]}`)
      .join('&')}`,
  );
const postDashboardPerformanceContract = (data) => api.post(`${prefix}/performance-contract`, data)
const postDashboardDriverPerformanceContract = (data) => api.post(`${prefix}/performance-driver`, data)
const postDashboardFleetPerformanceContract = (data) => api.post(`${prefix}/performance-fleet`, data)

export { getDashboard, postDashboardPerformanceContract, postDashboardDriverPerformanceContract, postDashboardFleetPerformanceContract };
